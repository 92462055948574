import router from '@/router/index.js'
import {
  requestResetPassword,
  resetPassword,
  changePassword,
  getPasswordPolicy,
  getUserDetails,
  getAuthenticationDetail
} from '@/api/user.js'
import {
  auth,
  validateOTP,
  requestOTP,
  enableTOTP,
  confirmTOTP,
  disableTOTP
} from '@/api/auth.js'
import { clearLocalStorage } from '@/utils/helpers.js'

const getDefaultState = () => {
  return {
    locale: 'en',
    dateFormat: 'yyyy-MM-dd',
    authenticated: false,
    required2FA: false,
    isPasswordExpired: false,
    appUser: {},
    username: '',
    details: {
      getAuthenticationDetail: {
        mfaDeliveryMethods: []
      },
      PasswordValidationPolicies: []
    }
  }
}

const state = getDefaultState()

const getters = {
  get: state => state,
  id: state => state.appUser.id,
  fullName: state => state.client.displayName,
  email: state => state.appUser.email,
  user: state => state.appUser,
  isTotpEnabled: (state) => state.details?.getAuthenticationDetail?.mfaDeliveryMethods?.find(method => method === 'TOTP'),
  isLegalEntity: state => state.client.legalForm === 2
}

const mutations = {
  resetState (state) {
    Object.assign(state, getDefaultState())
  },
  update (state, payload) {
    Object.assign(state, payload)
  },
  updateDetails (state, payload) {
    Object.assign(state, {
      ...state,
      details: {
        ...state.details,
        ...payload
      }
    })
  }
}

const actions = {
  resetState ({ commit }) {
    commit('resetState')
  },
  update (context, payload) {
    context.commit('update', payload)
  },
  async login ({ commit, dispatch }, credentials) {
    await auth(credentials)

    commit('update', { username: credentials.username })

    const isRequiredResetPassword = sessionStorage.getItem('required_password_reset') === 'true'

    if (isRequiredResetPassword) {
      router.push('/request-reset-password')
    } else {
      await dispatch('updateUserDetails')
      await dispatch('checkUnverifiedClient')

      if (router.currentRoute.name === 'registration') return

      await dispatch('checkSelfServiceUser')
      await dispatch('checkMFA')

      // checkMFA already push to otp if needed
      if (router.currentRoute.name === 'otp') return

      await dispatch('checkExpiredPassword')
      await dispatch('application/toggleLoadingScreen', '', { root: true })

      commit('update', { authenticated: true })
    }
  },
  async updateUserDetails ({ commit }) {
    const newDetail = (await getUserDetails()).data.data

    commit('updateDetails', {
      ...newDetail,
      PasswordValidationPolicies: newDetail.PasswordValidationPolicies.select
    })
  },
  async checkSelfServiceUser ({ state }) {
    const { isSelfServiceUser } = state.details.getAuthenticationDetail

    if (!isSelfServiceUser) {
      throw new Error('noPermissions')
    }
  },
  async checkUnverifiedClient ({ state }) {
    const client = state.details.Client

    if (client?.length > 0 && client[0].status === '100') { // 100 = Unverified client
      sessionStorage.setItem('clientId', client[0].id)
      return router.push({ name: 'registration' })
    }
  },
  async checkMFA ({ state, commit, getters }) {
    const { isMFARequired } = state.details.getAuthenticationDetail

    if (!isMFARequired) return

    if (!getters.isTotpEnabled) requestOTP()

    commit('update', { required2FA: true })

    await router.push({ name: 'otp' })
  },
  async checkExpiredPassword ({ state, commit }) {
    const { isPasswordExpired } = state.details.getAuthenticationDetail

    if (!isPasswordExpired) return

    commit('update', { isPasswordExpired: true })
    return router.push('/reset-password')
  },
  async logout ({ dispatch }) {
    await dispatch('application/resetState', '', { root: true })
    window.zE.hide()
    clearLocalStorage()
    await router.push({ name: 'login' })
  },
  async submitOtp ({ state, commit, dispatch }, otp) {
    const twoFA = await validateOTP(otp)
    if (twoFA.data.errors) {
      throw twoFA.data.errors
    }
    sessionStorage.setItem('2FA-Token', twoFA.data.data.requestAccessTokenFromOTP.token)
    const isPasswordExpired = state.details.getAuthenticationDetail.isPasswordExpired

    if (isPasswordExpired) {
      commit('update', { isPasswordExpired: true })
      return router.push('/reset-password')
    }

    await dispatch('application/toggleLoadingScreen', '', { root: true })

    commit('update', { authenticated: true })
  },
  async requestResetPassword (context, identifier) {
    const result = await requestResetPassword(identifier)
    if (result.data.errors && result.data.errors.length > 0) {
      throw result.data.errors[0]
    }
    return result
  },
  async resetPassword (context, payload) {
    const result = await resetPassword(payload)
    if (result.data.errors && result.data.errors.length > 0) {
      throw result.data.errors[0]
    }
    return result
  },
  async submitPasswordChanges ({ state }, password) {
    const result = await changePassword({ identifier: state.appUser.username, ...password })
    if (result.data.errors && result.data.errors.length > 0) {
      throw result.data.errors[0]
    }
    return result
  },
  async getPasswordPolicy ({ commit, state }) {
    if (state.details?.PasswordValidationPolicies.length) return

    const userDetails = await getPasswordPolicy()

    commit('updateDetails', { PasswordValidationPolicies: userDetails.data.data.PasswordValidationPolicies.select })
  },
  async updateAuthenticationDetail ({ commit }) {
    const newDetail = (await getAuthenticationDetail()).data.data
    commit('updateDetails', newDetail)
  },
  async enableTOTP () {
    return enableTOTP()
  },
  async disableTOTP ({ state, dispatch }, payload) {
    const dataTOTP = await disableTOTP(payload)

    if (dataTOTP.data.data === null) {
      throw new Error(dataTOTP.data.errors[0].message)
    }

    if (!state.details.getAuthenticationDetail.isMFARequired) {
      sessionStorage.removeItem('2FA-Token')
    }

    await dispatch('updateAuthenticationDetail')
  },
  async confirmEnableTOTP (_, payload) {
    const dataTOTP = await confirmTOTP(payload)

    if (!dataTOTP.data.data?.confirmTOTP) {
      throw new Error(dataTOTP.data.errors[0].message)
    }

    const { mfaToken, success, recoveryCodes } = dataTOTP.data.data.confirmTOTP

    if (dataTOTP.data.data.confirmTOTP.mfaToken) {
      sessionStorage.setItem('2FA-Token', mfaToken.token)
    }

    return { success, recoveryCodes }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
