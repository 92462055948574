import Vue from 'vue'
import Vidle from 'v-idle'
import VueSanitize from 'vue-sanitize'
import Zendesk from '@dansmaculotte/vue-zendesk'
import App from '@/App.vue'
import vuetify from './plugins/vuetify.js'
import i18n from '@/plugins/i18n.js'
import AppConfig from '@/plugins/appConfig.js'
import router from '@/router/index.js'
import store from '@/store/index.js'
import enabledComponents from '@/config/enabled.js'
import EventBus from '@/utils/eventBus.js'
import '@/styles/main.css'
import '@/styles/clientSpecificStyle'
// pwa manifest. Import this before service-workers
import '@/manifest.js'
// pwa service-workers
import '@/registerServiceWorker.js'
import '@/plugins/facade.js'

Vue.use(VueSanitize)
Vue.use(Vidle)
Vue.use(AppConfig, {
  enabledComponents
})

i18n.locale = store.state.user.locale

// global event bus
Vue.prototype.$bus = EventBus

Vue.config.productionTip = false

const zendeskKey = process.env.VUE_APP_ZENDESK_KEY
Vue.use(Zendesk, {
  key: zendeskKey,
  disabled: false,
  hideOnLoad: true,
  settings: {
    webWidget: {
      color: {
        theme: '#1E7DD4'
      },
      helpCenter: {
        originalArticleButton: false,
        title: {
          'en-US': 'Search for help',
          '*': 'Search for help'
        }
      },
      chat: {
        suppress: false,
        title: 'Talk to us'
      },
      contactForm: {
        title: {
          'en-US': 'Message us',
          '*': 'Contact us'
        }
      },
      launcher: {
        label: {
          'en-US': 'Support',
          '*': 'Support'
        }
      }
    }
  }
})

// only needed because of invalid cert on localhost
if (process.env.NODE_ENV === 'development') {
  process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'
}

window.app = new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
