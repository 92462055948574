import accounts from './accounts'
import application from './application'
import recipients from './recipients'
import cards from './cards'
import user from './user'
import transactions from './transactions'
import communications from './communications'
import transfers from './transfers'
import localSettings from './localSettings'
import pwa from './pwa'
import views from './views'
import registration from './registration'
import support from './support'
import organization from './organization'
import preferences from './preferences'

export default {
  accounts,
  application,
  recipients,
  cards,
  user,
  transactions,
  communications,
  transfers,
  localSettings,
  pwa,
  views,
  registration,
  support,
  organization,
  preferences
}
