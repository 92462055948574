import { restApi } from '@/api/request.js'
import { getTenantId } from '@/utils/helpers'

const request = restApi()

const downloadAccountReport = (reportType, accountId, dateFormat = 'MMMM dd yyyy') => {
  return request({
    method: 'get',
    url: `/self/runreports/${reportType}?output-type=PDF&tenantIdentifier=${getTenantId()}&locale=en&dateFormat=${dateFormat}&R_savingsId=${accountId}`,
    responseType: 'blob'
  })
}

export default downloadAccountReport
